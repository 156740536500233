import React from 'react'

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Parallax } from "react-scroll-parallax";
import { Link } from 'react-router-dom'

// Components
import PortfolioHabsoe from "../../../Components/Portfolio/PortfolioBoxedHabsoe";
import Header, { HeaderNav, Menu, SearchBar } from '../../../Components/Header/Header'


// Data
import { FilterData, portfolioHabsoeData } from '../../../Components/Portfolio/PortfolioHabsoeData'


const PortfolioHabsoePage = (props) => {
  return (
    <div style={props.style}>
            {/* Header Start */}
            <Header type="responsive-sticky">
                <HeaderNav fluid="fluid" theme="dark" bg="black" menu="light" className="px-[35px] py-[0px] md:!py-0 md:px-0">
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                        <Link aria-label="header logo" className="flex items-center" to="home-architecture">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="111" height="20" loading="lazy" src='/assets/img/habsoe-logo-new.png' data-rjs='/assets/img/habsoe-logo-new.png' alt='logo' />
                                <img className="alt-logo" width="111" height="20" loading="lazy" src='/assets/img/webp/logo-white.webp' data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
                                <img className="mobile-logo" width="111" height="20" loading="lazy" src='/assets/img/webp/logo-white.webp' data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block">
                        <Navbar.Toggle className="md:ml-[10px]">
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto pe-0 justify-start ml-96">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0 !pl-0">
                        <SearchBar className="!pl-[7px] xs:pl-[15px] pr-0" />
                       {/* <HeaderLanguage className="xs:pl-[15px]" /> */}
                       {/* <HeaderCart className="xs:pl-[15px]" style={{ "--base-color": "#0038e3" }} /> */}
                    </Col>
                </HeaderNav>
            </Header>
            {/* Header End */}

       {/* Parallax Section Start */}
       <div className="py-[80px] h-auto overflow-hidden md:relative md:py-[40px]">
        <Parallax className="lg-no-parallax absolute top-[0] w-full h-full lg:bg-cover bg-dark " translateY={[-40, 40]} ></Parallax>
        <Container className="h-full relative">
          <Row className="justify-center h-[60px] sm:h-[50px]">
            <Col xl={6} lg={6} sm={8} className="text-center flex justify-center flex-col font-serif">
             {/*  <h1 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e07d5f] to-[#ff798e] mb-[15px] inline-block text-xmd leading-[20px]">Portfolio classic masonry column</h1> */}
              <h2 className="text-lightgray font-medium -tracking-[1px] mb-0 mt-10">Claus Larsen_box</h2>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Parallax Section End */}

      <section className="pb-[130px] lg:pb-[90px] md:pb-[75px] sm:pb-[50px] bg-dark relative">
        <Container fluid>
          <Row>
            <Col xs={12} className="xs:px-0">
              <PortfolioHabsoe
                overlay={["#0039e3", "#4132e0", "#5e28dd", "#741bd9", "#8600d4"]}
                grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large"
                filterData={FilterData}
                data={portfolioHabsoeData}
                pagination={true}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default PortfolioHabsoePage